import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Suspense, useState, useEffect } from "react";
import {
  HeaderContainer,
  HeaderAddressWrapper,
  HeaderWrapper,
  AddressSvg,
  PhoneSvg,
  StyledAddress,
  StyledA,
  HeroImg,
  BurgerMenu,
  BurgerLine1,
  BurgerLine2,
  BurgerLine3,
  NavContainer,
  NavWrapper,
  LogoWrapper,
  StyledFooter,
  FooterText,
  PageName,
  NavBox,
  MenuLink,
  BtnFirst,
  BtnSecond,
  RelativeMain,
  FooterIcon,
  BtnWrapper,
  AccountLink,
} from "./SharedLayout.styled";
import { Loader } from "../Loader";
import locationIcon from "../../assets/svg/location.svg";
import phoneIcon from "../../assets/svg/phone.svg";
import logoMobile1x from "../../assets/images/logo-mobile@1x.png";
import logoMobile2x from "../../assets/images/logo-mobile@2x.png";
import logoDesktop1x from "../../assets/images/logo-desktop@1x.png";
import logoDesktop2x from "../../assets/images/logo-desktop@2x.png";
import { MainSwiper } from "../MainSwiper/MainSwiper";
import { MobileMenu } from "../MobileMenu";
import facebook from "../../assets/svg/facebook.svg";
import { Link } from "react-router-dom";

export const SharedLayout = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1279);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1279);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleBurgerMenu = () => {
      const line1El = document.getElementById("line1");
      const line2El = document.getElementById("line2");
      const line3El = document.getElementById("line3");
      if (isMobileMenuOpen) {
        line1El.style.transform = "translateY(-50%) rotate(-45deg)";
        line2El.style.opacity = "0";
        line3El.style.transform = "translateY(-50%) rotate(45deg)";
      } else {
        line1El.style.transform = "translateY(-7px)";
        line2El.style.opacity = "1";
        line3El.style.transform = "translateY(5px)";
      }
    };
    handleBurgerMenu();
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navigate = useNavigate();

  const handleMenuClick = (targetId) => {
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/", { state: { myId: targetId } });
    }

    setMobileMenuOpen(false);
  };

  const { state } = useLocation();
  const { myId } = state || {};

  useEffect(() => {
    const el = document.getElementById(myId);
    if (el) {
      el.scrollIntoView();
    }
  }, [myId]);

  const handleScroll = () => {
    window.scroll({
      top: 850,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isMobileMenuOpen && <MobileMenu handleMenuClick={handleMenuClick} />}
      <HeaderContainer>
        <HeaderAddressWrapper>
          <HeaderWrapper>
            <StyledAddress>
              <StyledA
                href="https://maps.app.goo.gl/rirvt9Yn9uFqvrDK7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AddressSvg>
                  <use href={locationIcon + "#location-icon"}></use>
                </AddressSvg>
                Aleje Ossolińskich 2, 85-093 Bydgoszcz
              </StyledA>
              <StyledA href="tel:+48789043230">
                <PhoneSvg>
                  <use href={phoneIcon + "#phone-icon"}></use>
                </PhoneSvg>
                +48 789 043 230
              </StyledA>
              {!isMobile && (
                <AccountLink to="/wesprzyj-nas" onClick={handleScroll}>
                  <b>Nr konta:</b> 48 1240 1183 1111 0010 7176 7241
                </AccountLink>
              )}
            </StyledAddress>
            <BurgerMenu onClick={toggleMobileMenu}>
              <BurgerLine1 id="line1" /> <BurgerLine2 id="line2" />
              <BurgerLine3 id="line3" />
            </BurgerMenu>
          </HeaderWrapper>
        </HeaderAddressWrapper>
        <NavContainer>
          <NavWrapper>
            <a href="/">
              <img
                srcSet={`
                  ${logoDesktop1x} 1x,
                  ${logoDesktop2x} 2x`}
                alt="logo"
              />
            </a>
            <NavBox>
              <MenuLink onClick={() => handleMenuClick("o-nas")}>
                O nas i naszym celu
              </MenuLink>
              <MenuLink onClick={() => handleMenuClick("nasz-patron")}>
                Nasz patron
              </MenuLink>
              <MenuLink onClick={() => handleMenuClick("wspolpraca")}>
                Współpraca
              </MenuLink>
              <MenuLink onClick={() => handleMenuClick("sprawozdania")}>
                Sprawozdania
              </MenuLink>
              <MenuLink onClick={() => handleMenuClick("kontakt")}>
                Kontakt
              </MenuLink>
            </NavBox>
            <Link to="/procent">
              <BtnSecond type="button" onClick={handleScroll}>
                Przekaż 1,5%
              </BtnSecond>
            </Link>
          </NavWrapper>
        </NavContainer>
      </HeaderContainer>
      <RelativeMain>
        <HeroImg>
          <LogoWrapper>
            <a href="/">
              <img
                srcSet={`
                  ${logoMobile1x} 1x,
                  ${logoMobile2x} 2x`}
                alt="logo"
              />
            </a>
          </LogoWrapper>
          <BtnWrapper>
            <Link to="/aktualnosci">
              <BtnSecond type="button" onClick={handleScroll}>
                Aktualności
              </BtnSecond>
            </Link>
            <Link to="/wesprzyj-nas">
              <BtnFirst
                type="button"
                className="show-on-mobile"
                onClick={handleScroll}
              >
                Wesprzyj nas
              </BtnFirst>
            </Link>
            {isMobile && (
              <Link to="/procent">
                <BtnSecond
                  type="button"
                  className="show-on-mobile"
                  onClick={handleScroll}
                >
                  Przekaż 1,5%
                </BtnSecond>
              </Link>
            )}
          </BtnWrapper>
        </HeroImg>
        <PageName>
          Bydgoskie Stowarzyszenie Miłosierdzia św. Wincentego a Paulo
        </PageName>
        <MainSwiper scrollFunc={handleScroll} />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </RelativeMain>
      <StyledFooter>
        <FooterIcon
          href="https://www.facebook.com/bsm.aic"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg width={24} height={24}>
            <use href={facebook + "#facebook-icon"}></use>
          </svg>
        </FooterIcon>
        <FooterText>
          <AccountLink to="/polityka-prywatnosci">
            Polityka prywatności
          </AccountLink>{" "}
          | Copyright ©2024 Bydgoskie Stowarzyszenie Miłosierdzia. All rights
          reserved.
          <br />
          KRS: 0000067399 | NIP: 5542319700 | REGON: 091355560
        </FooterText>
      </StyledFooter>
    </>
  );
};
