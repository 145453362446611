import { styled } from "styled-components";
import { Link, NavLink } from "react-router-dom";

export const SwiperContainer = styled.section`
  max-width: 360px;
  margin: 20px auto 0;

  @media screen and (min-width: 1280px) {
    max-width: 1065px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 675px;
  }
`;

export const SlideBox = styled.div`
  height: 215px;
  color: #fff;
  border-radius: 12px;
  background-color: #559aee;
  padding-bottom: 15px;
  position: relative;

  @media screen and (min-width: 1280px) {
    height: 292px;
    border-radius: 16px;
  }
`;

export const SlideSvg = styled.use`
  fill: #559aee;
  transition: fill 0.4s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const LinkSvg = styled(NavLink)`
  &.active ${SlideSvg} {
    fill: #f15265;
  }
`;

export const SvgContainer = styled.svg`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 17.75px 0 11.85px;

  &:hover ${SlideSvg}, &:focus ${SlideSvg} {
    fill: #f15265;
  }

  @media screen and (min-width: 1280px) {
    width: 98px;
    height: 98px;
  }
`;

export const SlideTitle = styled.h2`
  font-size: 12.5px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 6px;
  color: #fff;

  @media screen and (min-width: 1280px) {
    font-size: 17px;
    margin-bottom: 15px;
  }
`;

export const SlideText = styled.p`
  color: #fff;
  line-height: normal;
  font-size: 10px;
  font-weight: 500;
  max-width: 280px;
  text-align: center;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    font-size: 12px;
    max-width: 256px;
  }
`;

export const SlideLink = styled(Link)`
  color: #fff;
  font-size: 9px;
  font-weight: 700;
  text-decoration-line: underline;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 35px;
  transition: text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    text-shadow: 4px 3px 6px rgba(66, 68, 90, 1);
  }

  @media screen and (min-width: 1280px) {
    font-size: 12px;
  }
`;
