import React, { useEffect } from "react";
import {
  MenuContainer,
  MobileList,
  MenuItem,
  MenuLink,
} from "./MobileMenu.styled";

export const MobileMenu = ({ handleMenuClick }) => {
  useEffect(() => {
    const containerEl = document.getElementById("mobile-menu");
    setTimeout(() => {
      containerEl.style.transform = "translateX(0)";
    }, 0.5);
  }, []);

  return (
    <MenuContainer id="mobile-menu">
      <nav>
        <MobileList>
          <MenuItem>
            <MenuLink onClick={() => handleMenuClick("o-nas")}>
              O nas i naszym celu
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink onClick={() => handleMenuClick("nasz-patron")}>
              Nasz patron
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink onClick={() => handleMenuClick("wspolpraca")}>
              Współpraca
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink onClick={() => handleMenuClick("sprawozdania")}>
              Sprawozdania
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink onClick={() => handleMenuClick("kontakt")}>
              Kontakt
            </MenuLink>
          </MenuItem>
        </MobileList>
      </nav>
    </MenuContainer>
  );
};
