import { Routes, Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import { SharedLayout } from "./components/SharedLayout";

const Home = lazy(() => import("./pages/Home/Home"));
const BazylikaPomaga = lazy(() =>
  import("./pages/BazylikaPomaga/BazylikaPomaga")
);
const BarMleczny = lazy(() => import("./pages/BarMleczny/BarMleczny"));
const Ogrzewalnia = lazy(() => import("./pages/Ogrzewalnia/Ogrzewalnia"));
const DomMilosierdzia = lazy(() =>
  import("./pages/DomMilosierdzia/DomMilosierdzia")
);
const Stolowka = lazy(() => import("./pages/Stolowka/Stolowka"));
const PunktMedyczny = lazy(() => import("./pages/PunktMedyczny/PunktMedyczny"));
const PomocPrawna = lazy(() => import("./pages/PomocPrawna/PomocPrawna"));
const Dary = lazy(() => import("./pages/Dary/Dary"));
const Szkolenia = lazy(() => import("./pages/Szkolenia/Szkolenia"));
const Wyjazdy = lazy(() => import("./pages/Wyjazdy/Wyjazdy"));
const NaszPatron = lazy(() => import("./pages/NaszPatron/NaszPatron"));
const SprawozdaniaOgolne = lazy(() =>
  import("./pages/SprawozdaniaOgolne/SprawozdaniaOgolne")
);
const Sprawozdanie = lazy(() => import("./pages/Sprawozdanie/Sprawozdanie"));
const SprawozdaniaOOP = lazy(() =>
  import("./pages/SprawozdaniaOOP/SprawozdaniaOOP")
);
const SprawozdanieOOP = lazy(() =>
  import("./pages/SprawozdanieOOP/SprawozdanieOOP")
);
const Procent = lazy(() => import("./pages/Procent/Procent"));
const WesprzyjNas = lazy(() => import("./pages/WesprzyjNas/WesprzyjNas"));
const Aktualnosci = lazy(() => import("./pages/Aktualnosci/Aktualnosci"));
const BlogPost = lazy(() => import("./pages/BlogPost/BlogPost"));
const PolitykaPrywatnosci = lazy(() =>
  import("./pages/PolitykaPrywatnosci/PolitykaPrywatnosci")
);

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<Home />} />
        <Route path="bazylika-pomaga" element={<BazylikaPomaga />} />
        <Route path="bar-mleczny" element={<BarMleczny />} />
        <Route path="ogrzewalnia-u-miecia" element={<Ogrzewalnia />} />
        <Route path="dom-milosierdzia" element={<DomMilosierdzia />} />
        <Route path="stolowka" element={<Stolowka />} />
        <Route path="punkt-medyczny" element={<PunktMedyczny />} />
        <Route path="pomoc-prawna" element={<PomocPrawna />} />
        <Route path="dary" element={<Dary />} />
        <Route path="szkolenia" element={<Szkolenia />} />
        <Route path="wyjazdy" element={<Wyjazdy />} />
        <Route path="nasz-patron" element={<NaszPatron />} />
        <Route path="sprawozdania-ogolne" element={<SprawozdaniaOgolne />} />
        <Route path="sprawozdania-ogolne/:id" element={<Sprawozdanie />} />
        <Route path="sprawozdania-oop" element={<SprawozdaniaOOP />} />
        <Route path="sprawozdania-oop/:id" element={<SprawozdanieOOP />} />
        <Route path="procent" element={<Procent />} />
        <Route path="wesprzyj-nas" element={<WesprzyjNas />} />
        <Route path="aktualnosci" element={<Aktualnosci />} />
        <Route path="aktualnosci/:id" element={<BlogPost />} />
        <Route path="polityka-prywatnosci" element={<PolitykaPrywatnosci />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default App;
