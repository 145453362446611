import React, { useEffect, useState } from "react";
import church from "../../assets/svg/main-swiper-icons/church.svg";
import plate from "../../assets/svg/main-swiper-icons/plate.svg";
import heart from "../../assets/svg/main-swiper-icons/heart.svg";
import house from "../../assets/svg/main-swiper-icons/house.svg";
import {
  SwiperContainer,
  SlideBox,
  SvgContainer,
  SlideTitle,
  SlideLink,
  SlideText,
  SlideSvg,
  LinkSvg,
} from "./MainSwiper.styled";

import Swiper from "swiper";
import "swiper/css";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./customSwiperStyles.css";

export const MainSwiper = ({ scrollFunc }) => {
  const helpAreas = [
    {
      icon: `${church}#church-icon`,
      xMob: "14px",
      yMob: "14px",
      xDes: "20px",
      yDes: "19px",
      title: "BAZYLIKA POMAGA!",
      text: "Jeśli poszukujesz pracy, wsparcia psychicznego lub duchowego, kliknij poniżej, aby dowiedzieć się więcej.",
      reference: "bazylika-pomaga",
    },
    {
      icon: `${plate}#plate-icon`,
      xMob: "12.5px",
      yMob: "14px",
      xDes: "18.5px",
      yDes: "19.5px",
      title: "BAR MLECZNY",
      text: "Projekt “Bar Mleczny dla Ubogich”. To wszystko jest wynikiem WYŁĄCZNIE waszego zaangażowania!",
      reference: "bar-mleczny",
    },
    {
      icon: `${heart}#heart-icon`,
      xMob: "15px",
      yMob: "15px",
      xDes: "22px",
      yDes: "22px",
      title: "OGRZEWALNIA “U MIECIA”",
      text: "Jeśli szukasz ciepłego miejsca, zapraszamy do ogrzewalni “U Miecia”. Każdy zostanie przyjęty i dla każdego znajdzie się miejsce.",
      reference: "ogrzewalnia-u-miecia",
    },
    {
      icon: `${house}#house-icon`,
      xMob: "14px",
      yMob: "9px",
      xDes: "20px",
      yDes: "14px",
      title: "DOM MIŁOSIERDZIA",
      text: "Kryzys bezdomności to w dzisiejszych czasach powszechne zjawisko. Jeśli mierzysz się aktualnie z tym problemem, zapraszamy do naszego domu!",
      reference: "dom-milosierdzia",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1279);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1279);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const swiper = new Swiper(".main-slider", {
      modules: [Pagination, Navigation, Autoplay],
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 1,
      spaceBetween: 11,
      breakpoints: {
        1280: {
          slidesPerView: 3,
        },
      },
    });

    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <SwiperContainer>
      <div className="swiper main-slider">
        <div className="swiper-wrapper">
          {helpAreas.map((area, index) => (
            <div className="swiper-slide" key={index}>
              <SlideBox>
                <LinkSvg to={area.reference} onClick={scrollFunc}>
                  <SvgContainer>
                    <SlideSvg
                      href={area.icon}
                      x={isMobile ? area.xMob : area.xDes}
                      y={isMobile ? area.yMob : area.yDes}
                      width={isMobile ? 45 : 58}
                      height={isMobile ? 45 : 58}
                    ></SlideSvg>
                  </SvgContainer>
                </LinkSvg>
                <SlideTitle>{area.title}</SlideTitle>
                <SlideText>{area.text}</SlideText>
                <SlideLink to={area.reference} onClick={scrollFunc}>
                  DOWIEDZ SIĘ WIĘCEJ
                </SlideLink>
              </SlideBox>
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </SwiperContainer>
  );
};
