import { styled } from "styled-components";

export const MenuContainer = styled.div`
  position: fixed;
  top: 44px;
  right: 0;
  width: 189px;
  height: 650px;
  background-color: #559aee;
  z-index: 999;
  transform: translateX(1280px);
  transition: transform 250ms ease-in-out;
  box-shadow: -4px 2px 4px -1px rgba(0, 0, 0, 0.25);
  border-radius: 16px 0px 0px 16px;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const MobileList = styled.ul`
  margin-top: 104px;
  padding: 0 16px;
`;

export const MenuItem = styled.li`
  padding-left: 21px;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;

  &:not(:last-child) {
    border-bottom: 0.7px solid #b8d8ff;
  }
`;

export const MenuLink = styled.span`
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  transition: text-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    text-shadow: 4px 3px 6px rgba(66, 68, 90, 1);
  }
`;

export const BtnFirst = styled.button`
  padding: 10px 21px;
  border-radius: 53px;
  background: #559aee;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #b8d8ff;
  cursor: pointer;
  margin: 0 0 10px 36.5px;
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset;
  }
`;

export const BtnSecond = styled(BtnFirst)`
  background: #f15265;
  border: 1px solid #f15265;
`;
